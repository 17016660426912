import React, { useEffect, useState } from "react";
import { fetchApi,fetchAdminApi } from "../../../services/api";
import "../../../styles/totalregistration.css";
import moment from "moment";
import { useHistory } from "react-router";
import { FilterOutlined,SearchOutlined,ReloadOutlined ,CloseOutlined,BankOutlined }  from '@ant-design/icons';
import { Breadcrumb, Row, Spin, Col, Card, Form, Table, Tag, Tooltip, Space, Button,Radio,DatePicker,Select,Input,Divider,Typography, Checkbox  } from 'antd';
import constants from '../../../constants/constants';
import { displayOtherUniversityAlaska } from "../../../globalFunctions/GlobalFunctions";
import Loader from "../../Common/Cred/Loader";
const { Option } = Select;
const { Search } = Input;
const { Text, } = Typography;
const RegisteredStudent = (props) => {
	// const [radioButtonValue, setRadioButtonValue] = useState(1);
	const [radioButtonStatus, setRadioButtonStatus] = useState(3);
	const [form] = Form.useForm();
	const [listCountries, setCountries] = useState([]);
	const [listStates, setListStates] = useState([]);
	const [listUniversities, setUniversities] = useState([]);
	const [showFilterForm, setShowFilterForm] = useState(false);
	const history = useHistory();
	const pagination = { showSizeChanger: true };
	const { Meta } = Card;
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");
	// const [country, setCountry] = useState("");
	let sc =sessionStorage.getItem("searchCriteria");
	const [searchCriteria, setSearchCriteria] = useState(props.history.location.state ? props.history.location.state.serchCriteria:sc.replaceAll('"', ''));
	// const [state, setState] = useState("");
	const [role, setRole] = useState("");
	const [universityMessage, setUniversityMessage] = useState("");
	const [filteredSearchCriteria, setfilteredSearchCriteria] = useState("");
	// const [month,] = useState(props && props.history && props.history.location && props.history.location.state ? props.history.location.state : JSON.parse(localStorage.getItem("passedDate")) );
	const [university,] = useState(props && props.history && props.history.location && props.history.location.state ? props.history.location.state :JSON.parse(sessionStorage.getItem("university")));
	const [tableData, setTableData] = useState([]);
	const [stateBlock, setStateBlock] = useState(false);
	const [uniBlock, setUniBlock] = useState(false);
	const [tempUniversity, setTempUniversity] = useState([]);
	const [loader,setLoader]=useState(false);
	const [isCleared,setIsCleared]=useState(1);
	useEffect(() => {
		fetchStudentListing();
		fetchCountries();
		fetchStates();
		fetchUniversities();
		if (props && props.history && props.history.location && props.history.location.state) {
			if(props.history.location.state && props.history.location.state.state_id || props.history.location.state && props.history.location.state.country_id === "238"){
				setStateBlock(true);
				setUniBlock(true);
				// setState(props.history.location.state && props.history.location.state.state);
			}
			setUniversityMessage(props.history.location.state.university_name);
			setRole(props.history.location.state.role);
			// setState(props.history.location.state.state);
			// setCountry(props.history.location.state.country_code?props.history.location.state.country_code:"");
			setStartDate(moment(props.history.location.state.sd).format("MM/DD/YYYY"));
			setEndDate(moment(props.history.location.state.ed).format("MM/DD/YYYY"));
			sessionStorage.setItem("university", JSON.stringify(props.history.location.state));
			sessionStorage.setItem("searchCriteria", JSON.stringify(props.history.location.state.serchCriteria));
			localStorage.setItem("passedDate",JSON.stringify(props.history.location.state));
			if(props.history.location.state.isSearch === true){
				form.resetFields();
				form.setFieldsValue({ status:"5" });
				setTableData([]);
				setSearchCriteria("");
				setShowFilterForm(true);
			}
		}
	}, [props]);
	const fetchStudentListing = () =>{
		let url = `/list-registered-students?`;
		if(university){
			if(university.sd && university.ed){
				url += `start_date=${university.sd}&end_date=${university.ed}`;
			}
			if(university.roleId === 0){
				url += `&occupation_type=0`;
			}
			if(university.roleId){
				if(university.roleId ==="Unspecified"){
					url += `&occupation_type=${null}`;
				}else{
					url += `&occupation_type=${university.roleId}`;
				}
			}
			if(university.country_id){
				url += `&country_id=${university.country_id}`;
			}
			if(university.state_id){
				url += `&state_id=${university.state_id}`;
			}
			if(university.university_id){
				url += `&university_id=${university.university_id}`;
			}
		}
		if(!university.isSearch){
			fetchApiAdmin(url);
		}
	};
	const fetchCountries = () => {
		fetchApi(`/countries`, "get").then((res) => {
			if (res && res.data && res.data.length > 0) {
				//removed the US Country by filtering 
				let remainingArr = res.data.filter(data => data.country_id != '238');
				//Adding a US country at zero index
				remainingArr.unshift({
					country_id: "238",
					country_code: "US",
					country_name: "United States"
				});
				setCountries(remainingArr);
			} else {
				setCountries([]);
			}
		});
	};
	const fetchStates = () => {
		fetchApi(`/states?country_code=US`, "get").then((res) => {
			if (res && res.data && res.data.length > 0) {
				setListStates(res.data);
			} else {
				setListStates([]);
			}
		});
	};
	const fetchUniversities = () => {
		fetchApi(`/universities?country_code=US`, "get").then(
			(res) => {
				if (res && res.data) {
					Object.entries(res.data).forEach(([key, value]) => {
						displayOtherUniversityAlaska(key,value);
					});
					setUniversities(res.data);
				} else {
					setUniversities(null);
				}
			}
		);
	};
	const nameEditButton = (record,object) =>{
		let name = null;
		if (object.is_active === "4"){
			name = record;
		}else{
			name = record +" "+object?.stud_lastname?.charAt(0);
		}
		return (<span><Button type="link" onClick={() =>{
			sessionStorage.setItem("showEditSaveButton","show");
			// history.push({
			// 	pathname: "/edit-student",
			// 	state: { 
			// 		studentId: object.stud_id,
			// 		passedDate:props.location.state ? props.location.state : month
			// 	}
			// });
			localStorage.setItem("studentId", object.stud_id);
			// localStorage.setItem("editRedirectLink","/promo-code-list");
			window.open(constants.BaseUrl+"/edit-student", '_blank');
		}
		}>{name}</Button></span>);
	};
	const column = [
		{
			title: "Name",
			key: "stud_firstname",
			dataIndex: "stud_firstname",
			render: (record, object) => {
				if(object.is_active === "4"){
					// return <span>{"Stud - "+object.stud_id}</span>;
					return <Space>
						<Tooltip>
							{nameEditButton("Stud - "+object.stud_id,object)}
						</Tooltip>
					</Space>;
				}else{
					if (record.length > 15) {
						return <Space>
							<Tooltip title={record}>
								{nameEditButton(record.slice(0, 15)+"...",object)}
							</Tooltip>
						</Space>;
					} else {
						return nameEditButton(record,object);
					}
				}
			},
			sorter: (a, b) => {
				a = a.stud_firstname || '';
				b = b.stud_firstname || '';
				return a.localeCompare(b);
			},
			// sorter: (a, b) => a.name.localeCompare(b.name),
			width: 150
		},
		{
			title: "Username",
			key: "username",
			dataIndex: "username",
			render: (record,obj) => {
				if(obj.is_active === "4"){
					return <span></span>;
				}
				if (record.length > 15) {
					return <Space>
						<Tooltip title={record}>
							<span>{record.slice(0, 15)}...</span>
						</Tooltip>
					</Space>;
				} else {
					return <span>{ record}</span>;
					// if(obj.is_active === "3"){
					// 	return <span><a onClick={() => 
					// 	{
					// 		sessionStorage.setItem("showEditSaveButton","show");
					// 		history.push({
					// 			pathname: "/edit-student",
					// 			state: { 
					// 				studentId: obj.stud_id,
					// 				passedDate:props.location.state ? props.location.state : month
					// 			}
					// 		});
					// 	}
					// 	}>{record}</a></span>;
					// }else{
					// 	return <span>{ record}</span>;
					// }
				}
			},
			sorter: (a, b) => a.username.localeCompare(b.username),
			width: 150
		},
		{
			title: "Country",
			key: "country",
			dataIndex: "country",
			sorter: (a, b) => a.country.localeCompare(b.country),
			width: 100
		},
		{
			title: "State",
			key: "state",
			dataIndex: "state",
			sorter: (a, b) => {
				a = a.state || '';
				b = b.state || '';
				return a.localeCompare(b);
			},
			width: 100
		},
		{
			title: "University",
			key: "university",
			dataIndex: "university",
			sorter: (a, b) => {
				a = a.university || '';
				b = b.university || '';
				return a.localeCompare(b);
			},
			width: 300,
			render:(_record,type) =>{
				if(type.new_university =="YES"){
					return <span>{type&&type.university?type.university  :""}&nbsp;&nbsp;{<BankOutlined className="icon-md" style ={{ color:"grey" }}/>}</span>;
				}else{
					let city = type.city;
					return <span>{type&&type.university?type.university + "," + city :""}</span>;
				}
			},
		},
		{
			title: 'Results',
			key: 'test',
			render: (record) => {
				return (
					<Space size={8}>
						{record.tutorials_taken&&record.tutorials_taken!=="0"?
							// <>
							<span>{record.tutorials_taken}<a className="pl-1" type="link" onClick = {()=>{
								history.push({
									pathname:"/tutorial",
									state: { 
										progress:"Completed",
										type:1,
										count:record?.tutorials_taken,
										routeBack:"/registered-students",
										userId:record && record.stud_id,
										name:record?.stud_firstname +" "+record?.stud_lastname?.charAt(0),
										push2:university&& university.push2,
										push:university&& university.push,
										sd:university&& university.sd,
										ed:university&& university.ed,
										sded:university&& university.sded,
										title2:university&& university.title2,
										title:university&& university.title,
										serchCriteria:filteredSearchCriteria ? filteredSearchCriteria :university&& university.serchCriteria
									}
								});
							}}>Tu</a></span>
							// </>
							:""}
						{record.tests_taken&&record.tests_taken!=="0"?
							// <>
							<Text >{record.tests_taken}<Text strong className="pl-1 pointer" onClick = {()=>{
								history.push({
									pathname:"/test",
									state: { 
										progress:"Completed",
										count:record?.tests_taken,
										routeBack:"/registered-students",
										userId:record && record.stud_id,
										name:record?.stud_firstname +" "+record?.stud_lastname?.charAt(0),
										push2:university&& university.push2,
										push:university&& university.push,
										sd:university&& university.sd,
										ed:university&& university.ed,
										sded:university&& university.sded,
										title2:university&& university.title2,
										title:university&& university.title,
										serchCriteria:filteredSearchCriteria ? filteredSearchCriteria :university&& university.serchCriteria
									}
								});
							}}>T</Text></Text>
							// </>
							:""}
						{record.flashcards_taken&&record.flashcards_taken!=="0"?
							<span>{record.flashcards_taken}<a className="pl-1" type="link" onClick = {()=>{
								history.push({
									pathname:"/tutorial",
									state: { 
										progress:"Completed",
										is_flashcard:1,
										count:record?.flashcards_taken,
										routeBack:"/registered-students",
										userId:record && record.stud_id,
										name:record?.stud_firstname +" "+record?.stud_lastname?.charAt(0),
										push2:university&& university.push2,
										push:university&& university.push,
										sd:university&& university.sd,
										ed:university&& university.ed,
										sded:university&& university.sded,
										title2:university&& university.title2,
										title:university&& university.title,
										serchCriteria:filteredSearchCriteria ? filteredSearchCriteria :university&& university.serchCriteria
									}
								});
							}}>F</a></span>
							:""}
					</Space>
				);
			},
			align: 'center',
			width: 100
		},
		{
			title: "R",
			key: "role",
			dataIndex: "role",
			sorter: (a, b) => {
				a = a.role || '';
				b = b.role || '';
				return a.localeCompare(b);
			},
			render: (_record,type) => {
				let tooltip = "";
				if(type.role == "S"){
					tooltip = constants.Role_type.student;
				}else if( type.role == "P"){
					tooltip =constants.Role_type.professional;
				}else if ( type.role =="F"){
					tooltip =constants.Role_type.faculty;
				}else if ( type.role =="O"){
					tooltip =constants.Role_type.other;
				}else if ( type.role ==null){
					tooltip =constants.Role_type.unspecified;
				}
				return <Tooltip title={tooltip}>
					<span>{type.role === null ? "U":type.role}</span>
				</Tooltip>;
			},
			width: 50,
			align: 'center',
		},
		{
			title: "Registered On",
			key: "dt_created",
			dataIndex: "dt_created",
			render: (record) => {
				return <span>{record?moment(record).format('MM/DD/YYYY'): ""}</span>;
			},
			sorter: (a, b) => new Date(a.dt_created) - new Date(b.dt_created),
			width: 150,
		},
		{
			title: "Valid Until",
			key: "valid_till",
			dataIndex: "valid_till",
			render: (record) => {
				return <span>{record? moment(record).format('MM/DD/YYYY'): ""}</span>;
			},
			sorter: (a, b) => new Date(a.valid_till) - new Date(b.valid_till),
			width: 150,
		},
		{
			title: 'Status',
			key: 'is_active',
			render: (record) => {
				switch (record.is_active){
				case "0":
					return <Tag color={'red'}>Inactive</Tag>;
				case "1":
					return <Tag color={'blue'}>Active</Tag>;
				case "2":
					return <Tag color={'yellow'}>Pending</Tag>;
				case "3":
					return <Tag color={'default'}>Closed</Tag>;
				case "4":
					return <Tag color={'default'}>Cleared</Tag>;
				}
			},
			align: 'center',
			width: 100
		},
	];
	// const onChangeRadioButton = (e) => {
	// 	setRadioButtonValue(e.target.value);
	// };
	const filterButton = () =>{
		setShowFilterForm(!showFilterForm);
		setFilterButtonFormValues();
	};
	const onChangeCountry = (_value, option) =>{
		if (option?.key==="US") {
			setStateBlock(true);
			setUniBlock(true);
		}
		else {
			setStateBlock(false);
			setUniBlock(false);
		}
		// setCountry(option?.key);
	};
	const onChangeState = (e, option) =>{
		// setState(option?.key);
		form.setFieldsValue({ university:null });
		Object.entries(listUniversities).forEach(([key, value]) => {
			if (key === option?.children) {
				let obj={};
				obj[e]=value;
				setTempUniversity(obj);
				// setUniBlock(true);
			}
			else if ( value === null) {
				setTempUniversity(null);
				// setUniBlock(false);
			}	
		});
	};
	const onChangeUniversity = (_value, option) =>{
		setUniversityMessage(option?.children);
	};
	const onChangeStatus = (checkedValues) =>{
		setRadioButtonStatus(checkedValues);
	};
	const from = (_date, dateString) => {
		setStartDate(dateString);
	};
	const to = (_date, dateString) => {
		setEndDate(dateString);
	};
	const onChangeRole = (_value,option) => {
		setRole(option?.children);
	};
	const disabledFutureDate = (current) => {
		return current && current > moment().endOf('day');
	};
	const disabledPastDate = (current) => {
		const startdate = moment(startDate);
		const today = moment().endOf("day");
		return !(startdate.isSameOrBefore(current) && today.isAfter(current));
	};
	const filterSearchCriteriaMessage = (values) =>{
		let array = [];
		if(values){
			if(values.role){
				let	roleName = "";
				if(values.role === "0"){
					roleName = constants.Role_type.student;
				}else if(values.role === "1"){
					roleName = constants.Role_type.professional;
				}else if(values.role === "2"){
					roleName = constants.Role_type.faculty;
				}else if(values.role === "3"){
					roleName = constants.Role_type.other;
				}else if(values.role === "4"){
					roleName = constants.Role_type.unspecified;
				}
				array.push(`Role: ${role ? role: roleName}`);
			}
			if(values.university){
				array.push(`University: ${universityMessage}`);
			}
			if(values.country){
				let countryName="";
				listCountries.map((country)=>{
					if(country?.country_id === values.country){
						countryName = country.country_name;
						// return state.region_name;
					}
				});
				array.push(`Country: ${countryName}`);
			}
			if(values.state){
				let regionName="";
				listStates.map((state)=>{
					if(state?.region_id === values.state){
						regionName = state.region_name;
						// return state.region_name;
					}
				});
				array.push(`State: ${regionName}`);
			}
			if(values.from &&values.to){
				array.push(`Registrations from ${startDate} to ${endDate}`);
			}
			if(values.name){
				array.push(`Name: ${values.name}`);
			}
			if(array.length>=1){
				setfilteredSearchCriteria( array.join(", ")+".");
			}else{
				setfilteredSearchCriteria( array.join(", "));
			}
		}
	};
	const onFinishFilterSearch = (values) =>{
		let fetch = `/list-registered-students?`;
		if(values){
			if(values.country){
				fetch += `&country_id=${values.country}`;
			}
			if(values.from && values.to){
				fetch += `&start_date=${moment(values.from).format("YYYY-MM-DD")}&end_date=${moment(values.to).format("YYYY-MM-DD")}`;
			}
			if(values.name){
				fetch += `&name=${values.name}`;
			}
			if(values.state){
				fetch += `&state_id=${values.state}`;
			}
			if(values.university){
				fetch += `&university_id=${values.university}`;
			}
			if(values.status){
				if(values.status =="5"){
					fetch += "";
				}else{
					fetch += `&is_active=${values.status}`;
				}	
			}
			if(values.role){
				if(values.role === "4"){
					fetch += `&occupation_type=${null}`;
				}else{
					fetch += `&occupation_type=${values.role}`;
				}
			}
		}
		filterSearchCriteriaMessage(values);
		fetchApiAdmin(fetch);
	};
	const fetchApiAdmin =(fetch)=>{
		setLoader(true);
		fetch += `&include_cleared=${isCleared}`;
		fetchAdminApi(fetch, "get").then((res) => {
			if (res && res.data) {
				setTableData(res.data);
				setLoader(false);
			}else{
				setTableData([]);
				setLoader(false);
			}
		});
	};
	const setFilterButtonFormValues = () =>{
		let fromDate = university?.sd;
		let toDate = university?.ed;
		let regionName="";
		if(university?.state_id){
			listStates.map((state)=>{
				if(state?.region_id === university?.state_id){
					regionName = state.region_name;
					// return state.region_name;
				}
			});
		}
		form.setFieldsValue({ university:null });
		Object.entries(listUniversities).forEach(([key, value]) => {
			if (key ===regionName) {
				let obj={};
				obj[university?.state_id]=value;
				setTempUniversity(obj);
				// setUniBlock(true);
			}
			else if ( value === null) {
				setTempUniversity(null);
				// setUniBlock(false);
			}	
		});
		form.setFieldsValue({
			status:"5",
			// registered_valid:  1,
			from:moment(fromDate),
			to:moment(toDate),
			country:university?.country_id,
			state:university?.state_id,
			university:university?.university_id,
			role:university?.roleId ==="Unspecified"?"4":university?.roleId
		});
	};
	const filterReset = () =>{
		form.resetFields();
		form.setFieldsValue({ 
			status:"5",
			// registered_valid:  1
		});
		setfilteredSearchCriteria("");
		setSearchCriteria("");
		setTableData([]);
	};
	const selectFilterOption = (input,option)=>{
		return option.props.children.toLowerCase().indexOf(input.toLowerCase()) > -1 && option.props.children.toLowerCase().indexOf(input.toLowerCase()) < 1;
		// option.key.toLowerCase().indexOf(input.toLowerCase()) > -1 && option.key.toLowerCase().indexOf(input.toLowerCase()) < 1;
	};
	const checkNameentered=(nameValue)=>{
		if(nameValue.trim().length>0){
			setIsCleared(0);
		}else{
			setIsCleared(1);
		}
	};
	return (
		<>
			<div className="div">
				<Spin spinning={loader} size="large" indicator={<Loader/>}>
					<>
						<div className="p-3">
							<Breadcrumb>
								<Breadcrumb.Item ><a onClick={() => {
									history.push({ pathname: "/admin-dashboard" });
								}}>Home</a></Breadcrumb.Item>
								<Breadcrumb.Item ><a onClick = {() => {
									history.push({ pathname :"/analysis-menu" });
								}}>Analysis</a></Breadcrumb.Item>
								{university && university.title &&(
									<Breadcrumb.Item><a onClick={() => {
										history.push({ 
											pathname:university&& university.push2 ? university.push2 :university.push ,
											state : {
												sd : university && university.sded ===2 ? "":university.sd, 
												ed : university && university.sded ===2 ? "": university.ed,
											} 
										});
									}}>{university&&university.title2 ? university.title2 :university.title}</a></Breadcrumb.Item>
								)}
								{university && university.title2 && (
									<Breadcrumb.Item><a onClick={() => {
										history.push({ 
											pathname:university&& university.push ,
											state : {
												sd : university && university.sded ===2 ? "":university.sd, 
												ed : university && university.sded ===2 ? "": university.ed,
											} 
										});
									}}>{university&&university.title}</a></Breadcrumb.Item>
								)}
								<Breadcrumb.Item>Registered Students</Breadcrumb.Item>
							</Breadcrumb>
						</div>
						<div>
							<Row gutter={[24, 24]}>
								<Col xs={24} sm={24} md={24} lg={24}>
									<Card className = "card" size="small" title={<Meta title={<span className="text-uppercase font-14">Students</span>} description={<span style={{
										color: "black",
										fontStyle: "italic",
										whiteSpace:"break-spaces"
									}}>{`Search Criteria: ${filteredSearchCriteria ? `${filteredSearchCriteria}` :searchCriteria ?`${searchCriteria}.`:""}`}</span>}/>} extra={
										<Button className = {showFilterForm === false ? "edit-butoon" : "filterbutton"} onClick = {filterButton} icon={<FilterOutlined/>}></Button>
									}>
										<Row gutter={[24, 8]}>
											<Col xs={24} sm={24} md={24} lg={24}>
												<div className = {showFilterForm ===true ? "border p-3": ""}>
													{showFilterForm &&
											(<Form layout="vertical" form={form} onFinish={onFinishFilterSearch}>
												{/* <Form.Item className="label-default mb-0" name="registered_valid">
													<Radio.Group onChange={onChangeRadioButton} value={radioButtonValue}>
														<Space direction = "horizontal" >
															<Radio value={1}>Registered</Radio>
															<Radio value={2}>Valid</Radio>
														</Space>
													</Radio.Group>
												</Form.Item>
												<br/> */}
												<Row gutter={[48,8]}>
													<Col span={8}>
														<Form.Item className="label-default mb-0" label="Country" name ="country">
															<Select 
																onChange={onChangeCountry} 
																placeholder={"Country"} 
																className = "w-100" 
																showSearch 
																allowClear
																filterOption={selectFilterOption}>
																{listCountries &&
														listCountries.map((listCountry) => (
															<Option key={listCountry.country_code} value={listCountry.country_id}>
																{listCountry.country_name}
															</Option>
														))}
															</Select>
														</Form.Item>
													</Col>
													<Col span={8}>
														{
															stateBlock ?
																<Form.Item className="label-default mb-0" label ="State" name ="state">
																	<Select 
																		onChange={onChangeState} 
																		placeholder="State" 
																		showSearch
																		allowClear
																		filterOption={selectFilterOption}>
																		{listStates &&
														listStates.map((ListState) => (
															<Option key={ListState.region_code} value={ListState.region_id}>
																{ListState.region_name}
															</Option>
														))}
																	</Select>
																</Form.Item>:
																""
														}
													</Col>
													<Col span={8}>
														{uniBlock?
															<Form.Item className="label-default mb-0" label="University"  name="university">
																<Select
																	placeholder="University" 
																	allowClear 
																	showSearch 
																	onChange={onChangeUniversity}
																	filterOption={selectFilterOption}
																>
																	{tempUniversity?
																	// eslint-disable-next-line no-unused-vars
																		Object.entries(tempUniversity).map(([ key,value]) => (
																			<>
																				{value &&
																					value.universities &&
																					value.universities.length > 0 &&
																					value.universities.map((universe) => (
																						<Option
																							key={universe.university_id}
																							value={universe.university_id}
																							universityN={universe.university_name}
																							region={universe.region_id}
																						>
																							{universe.university_name+(universe.city?","+universe.city:"")}
																						</Option>
																					))}
																			</>
																		)):
																		listUniversities &&
																		// eslint-disable-next-line no-unused-vars
																		Object.entries(tempUniversity ? tempUniversity : listUniversities).map(([ key,value]) => (
																			<>
																				{value &&
																					value.universities &&
																					value.universities.length > 0 &&
																					value.universities.map((universe) => (
																						<Option
																							key={universe.university_id}
																							value={universe.university_id}
																							universityN={universe.university_name}
																							region={universe.region_id}
																						>
																							{universe.university_name+(universe.city?","+universe.city:"")}
																						</Option>
																					))}
																			</>
																		))
																	}
																</Select>
															</Form.Item>
															:""}
													</Col>
													<Col span={8}>
														<Form.Item className="label-default mb-0" label ="From" name = "from" >
															<DatePicker format="MM/DD/YYYY" inputReadOnly={true} onChange={from} disabledDate={disabledFutureDate}style={{ width: '100%' }}/>
														</Form.Item>
													</Col>
													<Col span={8}>
														<Form.Item className="label-default mb-0" label ="To" name = "to">
															<DatePicker format="MM/DD/YYYY" inputReadOnly={true} onChange={to} disabledDate={disabledPastDate} className="w-100"/>
														</Form.Item>
													</Col>
													<Col span={8}>
														<Form.Item className="label-default mb-0"  label ="Role" name ="role">
															<Select placeholder = "Select" onChange = {onChangeRole} allowClear >
																<Option value={"0"}>Student</Option>
																<Option value={"1"}>Professional</Option>
																<Option value={"2"}>Faculty</Option>
																<Option value={"3"}>Other</Option>
																<Option value={"4"}>Unspecified</Option>
															</Select>
														</Form.Item>
													</Col>
													<Col span={8}>
														<Form.Item className="	label-default mb-0" label ="Name" name ="name">
															<Search placeholder="Name, Username, Email" onChange={(e)=>checkNameentered(e.target.value)}/>
														</Form.Item>
													</Col>
													<Col span={16}>
														<Form.Item className="label-default mb-0 " label ="Status" name = "status">
															{/* <Checkbox.Group options={options} defaultValue={['All']} onChange={onChangeStatus}/> */}
															<Radio.Group  onChange={onChangeStatus} value ={radioButtonStatus}>
																<Space>
																	<Radio value={"5"}>All</Radio>
																	<Radio value={"1"}>Active</Radio>
																	<Radio value={"0"}>Inactive</Radio>
																	<Radio value={"2"}>Pending</Radio>
																	<Radio value={"3"}>Closed</Radio>
																	<Radio value={"4"}>Cleared</Radio>
																</Space>
															</Radio.Group>
														</Form.Item>
													</Col>
												</Row>
												<Divider className="my-3"/>
												<div className="text-right">
													<Space >
														<Form.Item className="mb-0 visibility-hidden" label ="" name ="cleared">
															<Checkbox onChange={(e)=>setIsCleared(e.target.checked?1:0)}>Include Cleared</Checkbox>
														</Form.Item>
														<Form.Item className="mb-0">
															<Button htmlType="submit" type="primary" icon={<SearchOutlined/>}>Search</Button>
														</Form.Item>
														<Form.Item className="mb-0">
															<Button onClick = {() =>{ filterReset(); }} icon={<ReloadOutlined/>}>Reset</Button>
														</Form.Item>
														<Form.Item className="mb-0">
															<Button onClick ={()=>{ setShowFilterForm(false);}} icon={<CloseOutlined/>}  type="primary" danger>Close</Button>
														</Form.Item>
													</Space>
												</div>
											</Form>)}
												</div>
											</Col>
											<Col xs={24} sm={24} md={24} lg={24}>
												<br/>
												<span className="px-3">{tableData !== null ? `${tableData.length} record(s) found.` : ""}</span>
												{/* <span>{tableData !== null ? `${tableData.length} record(s) found.` : ""}</span> */}
												<Table className="gap-table mytest-table"
													columns={column}
													dataSource={tableData}
													pagination={pagination}
													locale={{ emptyText: 'No Records Found' }}
												/>
											</Col>
										</Row>
									</Card>
								</Col>
							</Row>
						</div>
					</>
				</Spin>
			</div>
		</>
	);
};
export default RegisteredStudent;
