/*
File Name: MyTests.js
Author: Diksha Lotlikar
Modified On: 23/02/2022
Description: User Test Details/Progress
API's used:
1) scorecards?stud_id=${userId}&month=11
*/
import React, { useState, useEffect } from "react";
import { Row, Col, Button, Space, Tooltip, Card, Table, BackTop, Select, Form, Tag,Modal, } from 'antd';
import { FileSearchOutlined, RedoOutlined, PlayCircleOutlined, HourglassOutlined, StarFilled,PauseOutlined } from '@ant-design/icons';
import { fetchApi } from "../../services/api";
import { COOKIE, getCookie } from "../../services/cookie";
import constants from '../../constants/constants';
import '../../styles/test.css';
import moment from "moment";
import { useHistory } from "react-router";
const { Option } = Select;
const MyTests = (props) => {
	const pagination = { showSizeChanger: true };
	const history = useHistory();
	const [scoreCard, setScoreCards] = useState([]);
	const [data, setData] = useState([]);
	const [pauseCount,setPauseCount]=useState(0);
	const [apiWait,setApiWait]=useState(false);
	const [isFilterValue,setIsFilterValue]=useState(props&&props.location&&props.location.state&&props.location.state.IsFilter?['Incomplete']:null);
	const [testFilterValue,setTestFilterValue]=useState(props&&props.location&&props.location.state&&props.location.state.type&&props.location.state.type==="category"?['FPP','SAED','PIET','MIXED']:props&&props.location&&props.location.state&&props.location.state.type&&props.location.state.type==="topic"?['Topics']:null);
	const [passFilterValue,setpassFilterValue]=useState(null);
	const [isFilterRetakeOrPausedValue,setIsFilterRetakeOrPausedValue]=useState(props&&props.location&&props.location.state&&props.location.state.isRetake&&props.location.state.isRetake==="true"?['Retake']:null);
	const [passPercentageDate,setPassPercentageDate]=useState("");
	const [isTypeModalVisible, setIsTypeModalVisible] = useState(false);
	const [retakeRecord,setRetakeRecord]=useState(null);
	const [timer,setTimer]=useState("");
	useEffect(() => {
		fetchScoreCards();
	}, []);
	//To Fetch the data for test taken by the user
	const fetchScoreCards = () => {
		let userId = getCookie(COOKIE.UserId);
		fetchApi(`/scorecards?stud_id=${userId}`, "get").then((res) => {
			if (res && res.code === 200) {
				setScoreCards( res.data );
				setData( res.data );
				setPauseCount(parseInt(res.pause_count));
				setApiWait(true);
				setPassPercentageDate(res.PASS_PERCENT_DATE);
			}else{
				setScoreCards([]);
				setPauseCount(0);
				setApiWait(true);
			}
		});
	};
	function errorModalDisplay(){
		Modal.error({ 
			// title: constants.pauseCountErrorTittle,
			icon: " ",
			content: <span>
				{/* {constants.pauseCountErrorContent} Click 
				<a onClick={()=>{
					Modal.destroyAll();
					history.push("/my-tests");
				}}> here</a> {constants.pauseCountErrorContentpart} */}
				<div className="status-container text-center">
					<PauseOutlined className="status-icon"/> 
					<h1 className="status-title">{constants.pauseCountErrorTittle}</h1>
					<div className="status-content">
						<p>{constants.pauseCountErrorContent}</p>
						<p className="mt-4"><span>Click to </span>
							<Button className="btnDb" onClick={()=>{
								setIsFilterValue(['Incomplete']);
								Modal.destroyAll();
							}}>
									View
							</Button> 
							<span> your paused tests.</span>
						</p>
					</div>
				</div>
			</span>,
			closable: true,
			okButtonProps : { style: { display: 'none' } },
			// onOk() {
			// },
		});
	}
	//Cancel function for popup modal
	const handleTypeCancel = () => {
		setRetakeRecord(null);
		setTimer("");
		setIsTypeModalVisible(false);
	};
	// Table filter by date
	const onChangeTable = (e) => {
		let myTestsData = null;
		let date = new Date();
		if (e === "1") {
			let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
			let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
			myTestsData = scoreCard.filter((a) => {
				let attemptedDate = new Date(a.attempt_date);
				attemptedDate.setHours(0,0,0,0);
				return attemptedDate >= firstDay && attemptedDate <= lastDay;
			});
		}
		else if(e === "2") {
			let current2=new Date();
			let lastDay2 = moment(current2);
			let firstDay2 = moment(current2).subtract(2,'months');
			myTestsData = scoreCard.filter((a) => {
				let attemptedDate =  moment(a.attempt_date);
				return  moment(attemptedDate).isBetween(firstDay2, lastDay2);
			});
		} 
		else if(e === "3") {
			let current3=new Date();
			let lastDay3 =moment(current3);
			let firstDay3 = moment(current3).subtract(3,'months');
			myTestsData = scoreCard.filter((a) => {
				let attemptedDate = moment(a.attempt_date);
				return  moment(attemptedDate).isBetween(firstDay3, lastDay3);
			});
		}
		else if(e === "4") {
			let current4=new Date();
			let lastDay4 = moment(current4);
			let firstDay4 = moment(current4).subtract(6,'months');
			myTestsData = scoreCard.filter((a) => {
				let attemptedDate =  moment(a.attempt_date);
				return  moment(attemptedDate).isBetween(firstDay4, lastDay4);
			});
		}
		else if(e === "5") {
			let current5=new Date();
			let lastDay5 = moment(current5);
			let firstDay5 = moment(current5).subtract(1,'years');
			myTestsData = scoreCard.filter((a) => {
				let attemptedDate =  moment(a.attempt_date);
				return moment(attemptedDate).isBetween(firstDay5, lastDay5);
			});
		}
		else if(e ==="6"){
			myTestsData=scoreCard;
		}
		setData(myTestsData);
	};
		// Sorting and Filtering Table data
	function onChange(_pagination, filters) {
		setIsFilterValue(filters.progress);
		setTestFilterValue(filters.test);
		setpassFilterValue(filters.result);
		setIsFilterRetakeOrPausedValue(filters.action);
	}
	// Top ten table
	const topTenColumns = [
		{
			title: '#',
			key: 'index',
			sorter: (a, b) => a.sr_no - b.sr_no,
			render: (record) => {
				return (
					<span>
						{record&&record.sr_no? record.sr_no:""}
					</span>
				);
			}
		},
		{
			title: 'Test',
			key: 'test',
			filters: [
				{
					text: 'FPP',
					value: 'FPP',
				},
				{
					text: 'SAED',
					value: 'SAED',
				},
				{
					text: 'PIET',
					value: 'PIET',
				},
				{
					text: 'Mixed',
					value: 'MIXED',
				},
				{
					text: 'Topics',
					value: 'Topics',
				},
				{
					text: 'Full Length',
					value: 'Full Length',
				},
			],
			filteredValue:testFilterValue,
			onFilter: (value, record) => {
				if (value==='FPP'){
					return record.test.includes('FPP');
				}
				else if  (value=== 'SAED'){
					return record.test.includes('SAED');
				}
				else if  (value==='PIET'){
					return record.test.includes('PIET');
				}
				else if  (value==='MIXED'){
					return record.test.includes('MIXED');
				}
				else if  (value==='Full Length'){
					return record.test.includes('Full Length');
				}
				else if  (value==='Topics'){
					return  !record.test.includes('FPP') && !record.test.includes('PIET') && !record.test.includes('MIXED') && !record.test.includes('SAED') && !record.test.includes('Full Length');
				}
				else {
					return record.test;
				}
			},
			render: (record) => {
				return (
					<span>{record&&record.test?record.test:""}</span>
				);
			}
		},
		{
			title: 'Attempt',
			key: 'attempt',
			align: 'center',
			render: (record) => {
				return (
					<span>{record&&record.attempt?record.attempt:""}</span>
				);
			}
		},
		{
			title: 'Mode',
			key: 'mode',
			align: 'center',
			render: (record) => {
				return (
					<span>
						{record&&record.time != null&&record.is_timed&&record.is_timed==="t"? <HourglassOutlined/>:""}
					</span>
				);
			}
		},
		{
			title: 'Progress',
			key: 'progress',
			filters: [
				{
					text: 'Complete',
					value: 'Completed',
				},
				{
					text: 'Incomplete',
					value: 'Incomplete',
				},
			],
			// defaultFilteredValue:props&&props.location&&props.location.state&&props.location.state.IsFilter?['Incomplete']:[],
			filteredValue:isFilterValue,
			onFilter: (value, record) => {
				let test_progress = "";
				if (record.status==="1") {
					test_progress = "Complete";
				} 
				else {
					test_progress = "Incomplete";
				}
				return value.indexOf(test_progress)===0;
			},
			render: record => {
				let color="";
				let ifcompleted = "";
				let progress = '';
				if (record.status==="1") {
					progress = "Completed";
					color="green";
					if(record.time != null&&record.is_timed&&record.is_timed==="t"){
						ifcompleted="in " + record.time;
					}
				}
				else {
					color="blue";
					progress = record.answered_questions + "/" + record.total_questions;
					if(record.time != null){
						ifcompleted=<span>{record.remaining_test_time}<span className="cyan"> remaining</span></span>;
					}
				}
				return(
					<>
						{record&&record.status&&record.status==="2"?
							<Tag color={"red"}>
								{"Paused"}
							</Tag>:record.status==="0"?<Tag color={"orange"}>
								{"In Progress"}
							</Tag>:null}
						<Tag color={color}>
							{progress}
						</Tag>
						{ifcompleted}
					</>
				);
			}
		},
		{
			title: 'Actions',
			align: 'center',
			key:'action',
			filters: [
				{
					text: 'Retake',
					value: 'Retake',
				},
				{
					text: 'Paused',
					value: 'Paused',
				},
			],
			// defaultFilteredValue:props&&props.location&&props.location.state&&props.location.state.IsFilter?['Incomplete']:[],
			filteredValue:isFilterRetakeOrPausedValue,
			onFilter: (value, record) => {
				if (value==="Retake") {
					return record&&record.status==="1"&&record.is_retake_available==='YES';
				} 
				else if(value==="Paused"){
					return record.status==="0"||record.status==="2";
				}
			},
			render: (record) => {
				return (
					<Space>
						{record&&record.status&&record.status==="1"&&
							<Tooltip title={record.is_full_length === true ? "Review Incorrect" : "Review Test"}><Button type="text" icon={<FileSearchOutlined/>} size="small" onClick={()=>redirectResult(record)}></Button></Tooltip>
						}
						{record&&record.status&&record.status==="1"&&record.is_retake_available==='YES'?
							<Tooltip title="Retake Test">
								<Button type="text" icon={<RedoOutlined/>} size="small" onClick={()=>{retakeTest(record);}}></Button>
							</Tooltip>:""
						}
						{record&&record.status&&record.status==="0"||record.status==="2"?
							record.is_full_length===false&&<Tooltip title="Resume Test"><Button type="text" onClick={()=>{resumeTest(record);}} icon={<PlayCircleOutlined/>} size="small"></Button></Tooltip>
							:""
						}
					</Space>
				);
			}
		},
		{
			title: 'Attempted On',
			key: 'attemptedOn',
			sorter: (a, b) => new Date(a.attempt_date) - new Date(b.attempt_date),
			render: (record) => {
				return (
					<span>{record&&record.attempt_date ? (<span>{record.attempt_date}</span>) : ("-")}</span>
				);
			}
		},
		{
			title: 'Questions',
			key: 'questions',
			align:'center',
			className: 'td-right',
			render: (record) => {
				let per_result = record.total_corr_ans + "/" + record.total_questions;
				if (record.status==="1"){
					return (
						<span>{per_result}</span>
					);
				}
				else {
					return (
						<div></div>
					);
				}
			}
		},
		{
			title: 'Result',
			key: "result",
			align:'center',
			className: 'td-right result-col',
			sorter: (a, b) => a.percentage - b.percentage,
			filters: [
				{
					text: 'Pass',
					value: 'pass',
				},
				{
					text: 'Did not pass',
					value: 'fail',
				},
			],
			filteredValue:passFilterValue,
			onFilter: (value, record) => {
				let result = "";
				if ( record.status==="1"){
					let attemptedDate=record.attempt_date?moment(record.attempt_date).format("MM-DD-YYYY"):"";
					if(new Date(attemptedDate)>=new Date(passPercentageDate)){
						if (record.percentage >= constants.percentagePass){
							result = "pass";
						}else if (record.percentage < constants.percentagePass){
							result = "fail";
						}
					}else if(new Date(attemptedDate)<new Date(passPercentageDate)){
						if (record.percentage >= constants.percentPassForOlder) {
							result = "pass";
						}
						else if (record.percentage < constants.percentPassForOlder) {
							result = "fail";
						}
					}
					return result.indexOf(value)===0;
				}
			},
			render: record => {
				if (record.status==="1") {
					// let attemptedDate=record.attempt_date?moment(record.attempt_date).format("MM-DD-YYYY"):"";
					let attemptedDate=record.attempt_date?record.attempt_date:"";
					if(new Date(attemptedDate)>=new Date(passPercentageDate)){
						if (record.percentage >= constants.percentagePass){
							return (
								<div>
									<span className="p-relative">{record.percentage}% <><Tooltip title="Pass" placement="right"><StarFilled className="yellow mytest-star"/></Tooltip></></span>
								</div>
							);
						}else{
							return (
								<div>
									<span className="">{record.percentage}%</span>
								</div>
							);
						}
					}else if(new Date(attemptedDate)<new Date(passPercentageDate)){
						if (record.percentage >= constants.percentPassForOlder){
							return (
								<div>
									<span className="p-relative">{record.percentage}% <><Tooltip title="Pass" placement="right"><StarFilled className="yellow mytest-star"/></Tooltip></></span>
								</div>
							);
						}else{
							return (
								<div>
									<span className="">{record.percentage}%</span>
								</div>
							);
						}
					}
				}
				else{
					return (
						<div></div>
					);
				}
			}
		}
	];
	const resumeTest=(record)=>{
		if(record&&record.test&&record.test.includes("MIXED")||record.test.includes("Mixed")){
			let categoryDetails={};
			categoryDetails.type="normal";
			categoryDetails.catId=0;
			categoryDetails.catType="MIXED";
			categoryDetails.count=record.total_questions?Number(record.total_questions):0;
			categoryDetails.test=record.test?record.test:"";
			categoryDetails.attempt=record.attempt?Number(record.attempt):null;
			categoryDetails.scoreCardId=record.scorecard_id?Number(record.scorecard_id):null;
			categoryDetails.isRetake=record.is_retake_available&&record.is_retake_available==="NO"?true:false;
			categoryDetails.isResume=true;
			if(record.is_timed&&record.is_timed==="t"){
				categoryDetails.isTimed=true;
				if(record.total_questions&&record.total_questions===25||record.total_questions&&record.total_questions===50){
					const timeRemaining=record.remaining_test_time;
					categoryDetails.clock=calculateTimeInSecondsRemaining(timeRemaining);
					//for timer to send to api
					categoryDetails.totalSeconds=calculateTimeInSeconds(record.time);
				}
			}else if(record.is_timed&&record.is_timed==="f"){
				categoryDetails.isTimed=false;
			}
			history.push({
				pathname:"/resume-test",
				state:{ categoryDetails:categoryDetails } 
			});
		}
		if(record&&record.category_id){
			let categoryDetails={};
			categoryDetails.type="normal";
			categoryDetails.catId=Number(record.category_id);
			categoryDetails.catType=record.category_type?record.category_type:"";
			categoryDetails.test=record.test?record.test:"";
			categoryDetails.count=record.total_questions?Number(record.total_questions):0;
			categoryDetails.attempt=record.attempt?Number(record.attempt):null;
			categoryDetails.scoreCardId=record.scorecard_id?Number(record.scorecard_id):null;
			categoryDetails.isRetake=record.is_retake_available&&record.is_retake_available==="NO"?true:false;
			categoryDetails.isResume=true;
			if(record.is_timed&&record.is_timed==="t"){
				categoryDetails.isTimed=true;
				if(record.total_questions&&record.total_questions===25||record.total_questions&&record.total_questions===50){
					const timeRemaining=record.remaining_test_time;
					categoryDetails.clock=calculateTimeInSecondsRemaining(timeRemaining);
					//for timer to send to api
					categoryDetails.totalSeconds=calculateTimeInSeconds(record.time);
				}
			}else if(record.is_timed&&record.is_timed==="f"){
				categoryDetails.isTimed=false;
			}
			history.push({
				pathname:"/resume-test",
				state:{ categoryDetails:categoryDetails } 
			});
		}else if(record&&record.topic_id){
			let topicDetails={};
			topicDetails.type="normal";
			topicDetails.topicId=Number(record.topic_id);
			topicDetails.count=25;
			topicDetails.topicTitle=record.test;
			topicDetails.scoreCardId=record.scorecard_id?Number(record.scorecard_id):null;
			topicDetails.attempt=record.attempt?Number(record.attempt):"";
			topicDetails.isRetake=record.is_retake_available&&record.is_retake_available==="NO"?true:false;
			topicDetails.isResume=true;
			if(record.is_timed&&record.is_timed==="t"){
				topicDetails.isTimed=true;
				if(record.total_questions&&record.total_questions===25){
					const timeRemaining=record.remaining_test_time;
					topicDetails.clock=calculateTimeInSecondsRemaining(timeRemaining);
					//for timer to send to api
					topicDetails.totalSeconds=calculateTimeInSeconds(record.time);
				}
			}else if(record.is_timed&&record.is_timed==="f"){
				topicDetails.isTimed=false;
			}
			history.push({
				pathname:"/resume-test",
				state:{ topicDetails:topicDetails } 
			});
		}
	};
	const calculateTimeInSecondsRemaining =(timeRemaining)=>{
		let hms = timeRemaining;   // your input string
		let a = hms.split(':'); // split it at the colons
		// minutes are worth 60 seconds. Hours are worth 60 minutes.
		return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
	};
	const calculateTimeInSeconds=(time)=>{
		let hour=time;
		let tt=hour.split(":");
		return (+tt[0]) * 60 * 60 + (+tt[1]) * 60 + (+tt[2]);
	};
	const retakeTest=(record)=>{
		if(pauseCount>=constants.minimumPauseCount){
			errorModalDisplay();
		}else{
			if(record.total_questions===25){
				setTimer(constants.TwentyFiveQuestionTimerText);
			}else if(record.total_questions===50){
				setTimer(constants.FiftyQuestionTimerText);
			}
			setIsTypeModalVisible(true);
			setRetakeRecord(record);
		}
	};
	// Retake redirect
	const retakeRedirect=(record,isTimed)=>{
		if(record){
			if(record.test){
				if(record.test.includes("MIXED")||record.test.includes("Mixed")){
					let categoryDetails={};
					categoryDetails.type="normal";
					categoryDetails.catId=0;
					categoryDetails.catType="MIXED";
					categoryDetails.count=record.total_questions?Number(record.total_questions):0;
					categoryDetails.test=record.test?record.test:"";
					categoryDetails.attempt=record.attempt?Number(record.attempt):null;
					categoryDetails.scoreCardId=record.scorecard_id?Number(record.scorecard_id):null;
					categoryDetails.isRetake=record.is_retake&&record.is_retake==="f"?true:false;
					categoryDetails.isResume=false;
					if(isTimed==="t"){
						categoryDetails.isTimed=true;
					}else if(isTimed==="f"){
						categoryDetails.isTimed=false;
					}
					history.push({
						pathname:"/retake-test",
						state:{ categoryDetails:categoryDetails } 
					});
				}
				if(record.category_id){
					let categoryDetails={};
					categoryDetails.type="normal";
					categoryDetails.catId=Number(record.category_id);
					categoryDetails.catType=record.category_type?record.category_type:"";
					categoryDetails.count=record.total_questions?Number(record.total_questions):0;
					categoryDetails.test=record.test?record.test:"";
					categoryDetails.attempt=record.attempt?Number(record.attempt):null;
					categoryDetails.scoreCardId=record.scorecard_id?Number(record.scorecard_id):null;
					categoryDetails.isRetake=record.is_retake&&record.is_retake==="f"?true:false;
					categoryDetails.isResume=false;
					if(isTimed==="t"){
						categoryDetails.isTimed=true;
					}else if(isTimed==="f"){
						categoryDetails.isTimed=false;
					}
					history.push({
						pathname:"/retake-test",
						state:{ categoryDetails:categoryDetails } 
					});
				}else if(record.topic_id){
					let topicDetails={};
					topicDetails.type="normal";
					topicDetails.topicId=Number(record.topic_id);
					topicDetails.count=25;
					topicDetails.topicTitle=record.test;
					topicDetails.scoreCardId=record.scorecard_id?Number(record.scorecard_id):null;
					topicDetails.isRetake=record.is_retake&&record.is_retake==="f"?true:false;
					topicDetails.isResume=false;
					if(isTimed==="t"){
						topicDetails.isTimed=true;
					}else if(isTimed==="f"){
						topicDetails.isTimed=false;
					}
					history.push({
						pathname:"/retake-test",
						state:{ topicDetails:topicDetails } 
					});
				}
			}
		}
	};
	//To redirect to Result page
	const redirectResult=(historyObject)=>{
		if(historyObject.is_full_length===true){
			history.push({
				pathname: "/full-result-page",
				state: { 
					scoreCardId:historyObject.scorecard_id ,
					isReview:"t",
				}
			});
		}else{
			if(historyObject.is_topic===true){
				history.push({
					pathname: "/result-page",
					state: { 
						scoreCardId:historyObject.scorecard_id,
						resultTypeFull:false,
						testCategory:null,
						isTopic:true,
						isReview:"t"
					}
				});
			}else{
				if(historyObject.category_type==="MIXED"){
					history.push({
						pathname: "/result-page",
						state: { 
							scoreCardId:historyObject.scorecard_id,
							resultTypeFull:false,
							testCategory:historyObject.category_type,
							isTopic:false,
							isReview:"t"
						}
					});
				}else{
					history.push({
						pathname: "/result-page",
						state: { 
							scoreCardId:historyObject.scorecard_id,
							resultTypeFull:true,
							testCategory:historyObject.category_type,
							isTopic:false,
							isReview:"t"
						}
					});
				}			
			}	
		}
	};
	return (
		<div className="p-4">
			<BackTop/>
			<Row gutter={[24, 24]} className='my-test'>
				<Col xs={24} sm={24} md={24} lg={24}>
					<Card size="small" title={<span>{constants.MyTests}</span>} extra={
						<Space className="gap-40">
							{data.length>0?
								<Button type="primary" className="navy-btn" onClick={()=>{	history.push("/instructions");}}>{constants.TakeTest}</Button>
								:null}
							<Form layout="horizontal">
								<Form.Item className="label-default mb-0" label="Tests taken">
									<Select defaultValue="6" style={{ width: 175 }} onChange={onChangeTable} placeholder="Select period" >
										<Option value="1">This month</Option>
										<Option value="2">Last 2 months</Option>
										<Option value="3">Last 3 months</Option>
										<Option value="4">Last 6 months</Option>
										<Option value="5">Last 12 months</Option>
										<Option value="6">All</Option>
									</Select>
								</Form.Item>
							</Form>
						</Space>
					}>
						<Table className="gap-table mytest-table" 
							locale={{ emptyText: 'No Records Found' }}
							dataSource={data} 
							columns={topTenColumns}
							pagination={pagination}
							// showSizeChanger defaultCurrent={1} 
							// pagination={{
							// 	onChange(current) {
							// 		setPage(current);
							// 	}	
							// }}
							// onShowSizeChange={onShowSizeChange}
							onChange={onChange} 
						/>
					</Card>
					{apiWait?
						<>
							{data&&data.length===0&&
								<div className="nograph-div">
									<span>You have not taken any tests.</span>
									<span> Would you like to start now?</span>
									<span><Button type="primary" className="navy-btn mt-3" onClick={()=>{	history.push("/instructions");}}>{constants.TakeTest}</Button></span>
								</div> 
							}
						</>:null}
				</Col>
			</Row> 
			{/* Timed untimed modal */}
			<Modal
				title={<b>Select Timed or Untimed Test</b>}
				centered
				visible={isTypeModalVisible}
				onCancel={handleTypeCancel}
				footer={null}
				className="type-modal"
			>
				{retakeRecord&&retakeRecord.category_type?(
					<h2>
						<p style={{ textAlign:"center" }}>{retakeRecord.category_type}</p>
						<p style={{ textAlign:"center" }}>{retakeRecord&&retakeRecord.total_questions&&retakeRecord.total_questions} Questions</p>
					</h2>
				):""}
				<Row gutter={[8, 0]}>
					<Col span={12}>
						<Button
							block
							onClick={
								()=>{
									retakeRedirect(retakeRecord,"f");
								}
							}
						>
							<HourglassOutlined className="icon-md"/>
							<br/>
							{constants.Untimed}
							<br/>
							<span>{constants.UntimedSubTitle}</span>
						</Button>
					</Col>
					<Col span={12}>
						<Button 
							block
							onClick={
								()=>{
									retakeRedirect(retakeRecord,"t");
								}
							}
						>
							<HourglassOutlined className="icon-md"/>
							<br/>
							{constants.Timed}
							<br/>
							<span>{timer}</span>
						</Button>
					</Col>
				</Row>
			</Modal>
		</div>
	);
};
export default MyTests;
