import React, { useState, useEffect, useRef } from "react";
import {
	Input,
	Form,
	Row,
	Col,
	Checkbox,
	Button,
	Space,
	message,
} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import "../../../styles/login.css";
import { fetchApi } from "../../../services/api";
import constants from "../../../constants/constants";
import { useHistory } from "react-router";
import { COOKIE, getCookie, createCookie } from "./../../../services/cookie";
import Footer from "./../../../footer";
import Headers from "../../../header";
import Modal from "antd/lib/modal/Modal";
import CryptoJS from 'crypto-js';
import Book from './Book';
const Login = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [isLoggedIn,setIsLoggedIn]=useState(false);
	const [userName,setUserName]=useState("");
	const [password,setPassword]=useState("");
	const [rememberme,setRememberMe]=useState(false);
	const [isLoggedInModal,setIsLoggedInModal]=useState(false);
	const [form] = Form.useForm();
	const myRef = useRef();
	useEffect(() => {
		setFormLogin();
		let queryString = window.location.href;
		var urlParam = queryString.substring(queryString.lastIndexOf('?') + 1);
		if(urlParam==="type=feedback"){
			sessionStorage.setItem("feedback","feedback");
		}
		let userId = getCookie(COOKIE.UserId);
		if (userId) {
			history.push("/home");
		}
	}, [history]);
	useEffect(() =>{
		if(myRef && myRef.current){
			const { input } = myRef.current;
			input.focus();
		}
	});
	const handleUsername=(e)=>{
		setUserName(e.target.value);
	};
	const encryptData = (data, salt) =>{
		let encPass=CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();
		createCookie(COOKIE.UserPass,encPass , null, "/");
	};
	const setFormLogin=()=>{
		if(getCookie(COOKIE.UserName)){
			let userPassEnc  = CryptoJS.AES.decrypt(getCookie(COOKIE.UserPass), getCookie(COOKIE.UserName));
			let originalPass = JSON.parse(userPassEnc.toString(CryptoJS.enc.Utf8));
			setUserName(getCookie(COOKIE.UserName)!==null?getCookie(COOKIE.UserName):"");
			setPassword(originalPass!==null?originalPass:"");
			setRememberMe(getCookie(COOKIE.UserName)!==null&&getCookie(COOKIE.UserName)!==""?true:false);
			form.setFieldsValue({
				userName:getCookie(COOKIE.UserName),
				password:originalPass,
			});
		}
	};
	const handlePassword=(e)=>{
		setPassword(e.target.value);
	};
	//Executes on click of login button
	const onFinish = () => {
		if(userName&&password){
			let agent = navigator.userAgent;
			setLoading(true);
			let payload = null;
			payload = {
				username: userName,
				password: password,
				browser:agent,
			};
			if(isLoggedIn===true){
				payload.allow_login= 1;
			}
			fetchApi("/login", "post", payload)
				.then((res) => {
					setLoading(false);
					if (res && res.code && res.code === 200) {
						setIsLoggedIn(false);
						if(rememberme){
							encryptData(password,userName);
							createCookie(COOKIE.UserName,userName , null, "/");
						}else{
							createCookie(COOKIE.UserName,[] , null, "/");
							createCookie(COOKIE.UserPass,[] , null, "/");
						}
						createCookie(COOKIE.UserId, res.data.stud_id, null, "/");
						createCookie(COOKIE.FirstName, res.data.stud_firstname, null, "/");
						createCookie(COOKIE.LastName, res.data.stud_lastname, null, "/");
						createCookie(COOKIE.Token,res.token,null,"/");
						// window.location.href = constants.BaseUrl + "/home";
						let userIdFromMyProfile=sessionStorage.getItem("idFromMyProfile");
						if(userIdFromMyProfile){
							window.location.href = constants.BaseUrl + "/myprofile";
						}else{
							window.location.href = constants.BaseUrl + "/home";
						}
					} else {
						if(res&&res.message==="User already Logged-in"){
							setIsLoggedInModal(true);
							setIsLoggedIn(true);
						}else{
							message.error({
								content:res.message,
								duration:constants.MessageDisplayLong,
							});
						}
					}
				})
				.catch(() => {
					setLoading(false);
					message.error("Failed to login");
				});
		}
	};
	return (
		<div>
			<Headers/>
			{/* <Space style={{ 
				backgroundColor:"#eded87",
				whiteSpace:"nowrap"
			}}>
				<span style={{ 
					fontWeight:"bold" ,
					marginLeft:"20px",
					fontSize:"larger",
				}}>Important Notice :</span>
				<marquee  width="100%" direction="left" height="25px" style={{ 
					fontWeight:400,
					fontSize:"larger",
					paddingTop:"2px"
				}}>
					{constants.ImportantNotice}
				</marquee></Space> */}
			<div className="login-content flex-vertical-middle">
				<Row gutter={[{
					xs: 8,
					sm: 24,
					md: 48,
					lg: 8,
					xl: 8
				}, 8]} className="login-px">
					<Book/>
					<Col lg={24} xl={14} className="w-100 flex-middle">
						<div className="text-right signup-link">
							<Space>
								{constants.SignUpRedirect}
								<Button
									type="link"
									className="pl-0"
									onClick={() => {
										history.push("/registration");
									}}
								>
									{constants.SignUpRedirectLink}
								</Button>
							</Space>
						</div>
						<div className="login-col">
							<div className="ml-135">
								<h1>{constants.LoginHeader}</h1>
								<Form layout="vertical" form={form} onFinish={onFinish} autoComplete="off">
									<div className="login-form">
										<Form.Item
											label={constants.Username}
											name="userName"
											rules={[
												{
													required: true,
													message: constants.LoginUserNameValid,
												},
											]}
										>
											<Input autoFocus className="login-input" placeholder={constants.Username} onChange={handleUsername}/>
										</Form.Item>
										<Form.Item
											label={constants.Password}
											name="password"
											rules={[
												{
													required: true,
													message: constants.LoginPasswordValid,
												},
											]}
										>
											<Input.Password
												className="login-input"
												placeholder={constants.Password}
												iconRender={(visible) =>
													visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>
												}
												onChange={handlePassword}
											/>
										</Form.Item>
										<Row>
											<Col span={12}>
												<Form.Item className="test-options">
													<Checkbox checked={rememberme} onChange={(e)=>setRememberMe(e.target.checked)}>{constants.RememberMe}</Checkbox>
												</Form.Item>
											</Col>
											<Col span={12} className="text-right">
												<Form.Item>
													<Button
														type="link"
														className="pr-0"
														onClick={() => {
															history.push("/forgot-password");
														}}
													> 
														{constants.ForgotPsdRedirectLink}
													</Button>
												</Form.Item>
											</Col>
										</Row>
									</div>
									<Form.Item>
										<Button
											shape="round"
											className="gold-btn login-btn"
											// type="button"
											htmlType="submit"
											loading={loading}
											// onClick={()=>{onFinish();}}
											// href="/"
										>
											{constants.LoginBTN}
										</Button>
									</Form.Item>
								</Form>
								<div className="text-center">
									<Space>
										{constants.EmailRedirect}
										<Button
											type="link"
											className="pl-0"
											onClick={() => {
												history.push("/resend-email");
											}}
										>
											{constants.ResendLink}
										</Button>
									</Space>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
			<Footer/>
			<Modal
				title="Login"
				visible={isLoggedInModal}
				width={510}
				onCancel={()=>{setIsLoggedInModal(false);}}
				className="already-logged-modal"
				footer={[
					<Input
						ref={myRef}
						key="submit"
						type="button" 
						value="Use Here"
						className="input-btn-purple mt-2" 
						htmlType="submit"
						onClick={()=>{onFinish();}}
					/>,
					<Button key="close" onClick={()=>{setIsLoggedInModal(false);}} className="mt-2">
						{constants.CancelBTN}
					</Button>
				]}
			>
				<p>{constants.LoginRestriction}</p>
				<p>{constants.LoginRestrictionPart2}</p>
			</Modal>
		</div>
	);
};
export default Login;
