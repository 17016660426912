import React,{ useEffect,useState } from 'react';
import { Breadcrumb,Row, Col, Card, Table, BackTop, Select, Form,Tag,Space,Tooltip,Button } from 'antd';
import { fetchApi } from "../../../services/api";
import { FileSearchOutlined, HourglassOutlined, StarFilled } from '@ant-design/icons';
import moment from "moment";
import { useHistory } from "react-router";
import constants from "../../../constants/constants";
const { Option } = Select;
const Test =(props)=> {
	const history = useHistory();
	const [scoreCard, setScoreCards] = useState([]);
	const pagination = { showSizeChanger: true };
	const [data, setData] = useState([]);
	const [passPercentageDate,setPassPercentageDate]=useState("");
	const [payload] = useState(props && props.history && props.history.location && props.history.location.state && props.history.location.state?props.history.location.state:JSON.parse(sessionStorage.getItem("payload")));
	const [testFilterValue,setTestFilterValue]=useState([]);
	const [isFilterValue,setIsFilterValue]=useState([]);
	const [flashcardFiltervalue,setFlashcardFiltervalue]=useState([]);
	const [flashcardFilterTypevalue,setFlashcardFilterTypevalue]=useState([]);
	const [page,setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalCountReport, setTotalCountReport] = useState(0);
	const [passFilterValue,setpassFilterValue]=useState(null);
	useEffect(() => {
		fetchScoreCards();
		if(props && props.history && props.history.location && props.history.location.state){
			sessionStorage.setItem("payload", JSON.stringify(props.history.location.state));
			if (props.history.location.state && props.history.location.state.count){
				setTotalCountReport(props.history.location.state.count);
			}
			// test type filters for tutorial
			if(payload?.type === 1){
				if(props.location.state.testType&&props.location.state.testType==="FPP"){
					setTestFilterValue(['FPP']);
				}else if(props.location.state.testType&&props.location.state.testType==="SAED"){
					setTestFilterValue(['SAED']);
				}else if(props.location.state.testType&&props.location.state.testType==="PIET"){
					setTestFilterValue(['PIET']);
				}else if(props.location.state.testType&&props.location.state.testType==="Mixed/Custom"){
					setTestFilterValue(['CUSTOM']);
				}else if(props.location.state.testType&&props.location.state.testType==="Topic"){
					setTestFilterValue(['Topics']);
				}else{
					setTestFilterValue(null);
				}
				if(props.location.state.progress&&props.location.state.progress==="Inprogress"){
					setIsFilterValue(['Inprogress']);
				}else if(props.location.state.progress&&props.location.state.progress==="Completed"){
					setIsFilterValue(['Complete']);
				} 
			}else if(props.location.state.is_flashcard&&props.location.state.is_flashcard === 1){
				if(props.location.state.is_flashcard&&props.location.state.is_flashcard === 1){
					// filters for tutorial type
					if(props.location.state.testType&&props.location.state.testType==="Topic"){
						setFlashcardFilterTypevalue(['Topic']);
					}else if(props.location.state.testType&&props.location.state.testType==="Chapters"){
						setFlashcardFilterTypevalue(['Textbook']);
					}
					// filters for tutorial progress
					if(props.location.state.progress&&props.location.state.progress==="Paused"){
						setFlashcardFiltervalue(['Paused']);
					}else if(props.location.state.progress&&props.location.state.progress==="Completed"){
						setFlashcardFiltervalue(['Completed']);
					}
				}else{
					// test progress
					if(props.location.state.progress&&props.location.state.progress==="Inprogress"){
						setIsFilterValue(['Inprogress']);
					}else if(props.location.state.progress&&props.location.state.progress==="Paused"){
						setIsFilterValue(['Paused']);
					}else if(props.location.state.progress&&props.location.state.progress==="Completed"){
						setIsFilterValue(['Complete']);
					}
				}
			}else{
				//test type filters for test
				if(props.location.state.testType&&props.location.state.testType==="FPP"){
					setTestFilterValue(['FPP']);
				}else if(props.location.state.testType&&props.location.state.testType==="SAED"){
					setTestFilterValue(['SAED']);
				}else if(props.location.state.testType&&props.location.state.testType==="PIET"){
					setTestFilterValue(['PIET']);
				}else if(props.location.state.testType&&props.location.state.testType==="Mixed/Custom"){
					setTestFilterValue(['MIXED']);
				}else if(props.location.state.testType&&props.location.state.testType==="Topic"){
					setTestFilterValue(['Topics']);
				}else if(props.location.state.testType&&props.location.state.testType==="Full length"){
					setTestFilterValue(['Full Length']);
				}else if(props.location.state.total&&props.location.state.total==="total"){
					setTestFilterValue(null);
				}else{
					setTestFilterValue(null);
				}
				if(props.location.state.progress&&props.location.state.progress==="Inprogress"){
					setIsFilterValue(['Inprogress']);
				}else if(props.location.state.progress&&props.location.state.progress==="Completed"){
					setIsFilterValue(['Complete']);
				}
			}
		}
	}, []);
	//To Fetch the data for test taken by the user
	const fetchScoreCards = () => {
		let url = `/scorecards?stud_id=${payload && payload.userId}&month=11&is_admin=1`;
		if(payload && payload.type){
			url += `&type=${payload && payload.type}`;
		}
		else if(payload && payload.is_flashcard){
			url += `&is_flashcard=${payload && payload.is_flashcard}`;
		}
		fetchApi(url, "get").then((res) => {
			if (res && res.code === 200) {
				let data = res.data && res.data.filter(data => data?.is_retake === "t");
				let dataf = res.data && res.data.filter(data => data?.is_retake === "f");
				if(payload?.test ==="test"){
					if(payload?.retake){
						setData( data );
					}else if(payload?.retake === false){
						setData( dataf );
					}
				}else{
					setData( res.data );
					if(payload&& payload.fromTestResult || payload&& payload.fromTutorialResult || payload&& payload.fromFlashcardResult ){
						setTotalCountReport(res.data?.length);
					}
					if(props.location.state.testType&&props.location.state.testType==="Topic"&&payload?.type===1){
						let tempData=res.data.filter(item=>(item.test!=='FPP') && (item.test!=='PIET') && (item.test!=='CUSTOM') && (item.test!=='SAED'));
						setTotalCountReport(tempData.length);
					}else if(
						props.location.state.testType&&props.location.state.testType==="FPP"||
						props.location.state.testType&&props.location.state.testType==="SAED"||
						props.location.state.testType&&props.location.state.testType==="Mixed/Custom"||
						props.location.state.testType&&props.location.state.testType==="Full length"
					){
						setTotalCountReport(props.history.location.state.count);
					}
					else if((testFilterValue===null||testFilterValue.length<1)&& payload?.type===1){
						setTotalCountReport(res.data?.length);
					}
				}
				setScoreCards( res.data );
				setPassPercentageDate(res.PASS_PERCENT_DATE);
			}else{
				setScoreCards([]);
				setData( [] );
				setTotalCountReport("0");
			}
		});
	};
	const onChangeTable = (e) => {
		let tableData = null;
		let date = new Date();
		if (e === "1") {
			let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
			let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
			tableData = scoreCard.filter((a) => {
				let setDate = new Date(a.attempt_date);
				setDate.setHours(0,0,0,0);
				return setDate >= firstDay && setDate <= lastDay;
			});
		}
		else if(e === "2") {
			let firstDay2 = new Date(date.getFullYear(), date.getMonth() - 1, 1);
			let lastDay2 = new Date(date.getFullYear(), date.getMonth() - 1 + 1, 0);
			tableData = scoreCard.filter((a) => {
				let setDate = new Date(a.attempt_date);
				setDate.setHours(0,0,0,0);
				return setDate >= firstDay2 && setDate <= lastDay2;
			});
		} 
		else if(e === "3") {
			let firstDay3 = new Date(date.getFullYear(), date.getMonth() + 1- 3, 1);
			let lastDay3 = new Date(date.getFullYear(), date.getMonth() + 1 - 1 + 1, 0);
			tableData = scoreCard.filter((a) => {
				let setDate = new Date(a.attempt_date);
				setDate.setHours(0,0,0,0);
				return setDate >= firstDay3 && setDate <= lastDay3;
			});
		}
		else if(e === "4") {
			let firstDay4 = new Date(date.getFullYear(), date.getMonth() + 1 - 6, 1);
			let lastDay4 = new Date(date.getFullYear(), date.getMonth() + 1 - 1 + 1, 0);
			tableData = scoreCard.filter((a) => {
				let setDate = new Date(a.attempt_date);
				setDate.setHours(0,0,0,0);
				return setDate >= firstDay4 && setDate <= lastDay4;
			});
		}
		else if(e === "5") {
			let firstDay5 = new Date(date.getFullYear(), date.getMonth() + 1 - 12 , 1);
			let lastDay5 = new Date(date.getFullYear(), date.getMonth() + 1 -1 + 1, 0);
			tableData = scoreCard.filter((a) => {
				let setDate = new Date(a.attempt_date);
				setDate.setHours(0,0,0,0);
				return setDate >= firstDay5 && setDate <= lastDay5;
			});
		}
		else if(e ==="6"){
			tableData=scoreCard;
		}
		setData(tableData);
	};
	const redirectResult=(historyObject,object)=>{
		console.log("object",object);
		if(historyObject.is_full_length===true){
			let obj =  { 
				scoreCardId:historyObject.scorecard_id ,
				isReview:"t",
				userId:historyObject.stud_id,
				fromTestResult:true,
				returnTest:"Test Results"
			};
			sessionStorage.setItem("TestresultObject", JSON.stringify(obj));
			window.open(constants.BaseUrl+"/full-length-result-page", '_blank');
		}else{
			if(historyObject.is_topic===true){
				let obj = { 
					scoreCardId:historyObject.scorecard_id,
					resultTypeFull:false,
					testCategory:null,
					isTopic:true,
					isReview:"t",
					userId:historyObject.stud_id,
					fromTestResult:true,
					returnTest:"Test Results"
				};
				sessionStorage.setItem("TestresultObject", JSON.stringify(obj));
				window.open(constants.BaseUrl+"/test-result", '_blank');
			}else{
				if(historyObject.category_type==="MIXED"){
					let obj = { 
						scoreCardId:historyObject.scorecard_id,
						resultTypeFull:false,
						testCategory:historyObject.category_type,
						isTopic:false,
						isReview:"t",
						userId:historyObject.stud_id,
						fromTestResult:true,
						returnTest:"Test Results"
					};
					sessionStorage.setItem("TestresultObject", JSON.stringify(obj));
					window.open(constants.BaseUrl+"/test-result", '_blank');
				}else{
					let obj =  { 
						scoreCardId:historyObject.scorecard_id,
						resultTypeFull:true,
						testCategory:historyObject.category_type,
						isTopic:false,
						isReview:"t",
						userId:historyObject.stud_id,
						fromTestResult:true,
						returnTest:"Test Results"
					};
					sessionStorage.setItem("TestresultObject", JSON.stringify(obj));
					window.open(constants.BaseUrl+"/test-result", '_blank');
				}			
			}	
		}
	};
	// const redirectResult=(historyObject)=>{
	// 	console.log("historyObject",historyObject);
	// 	if(historyObject.is_full_length===true){
	// 		history.push({
	// 			pathname: "/full-length-result-page",
	// 			state: { 
	// 				scoreCardId:historyObject.scorecard_id ,
	// 				isReview:"t",
	// 				userId:payload?.userId,
	// 				name:payload?.name,
	// 				push2:payload?.push2,
	// 				push:payload?.push,
	// 				sd:payload?.sd,
	// 				ed:payload?.ed,
	// 				sded:payload?.sded,
	// 				title2:payload?.title2,
	// 				title:payload?.title,
	// 				serchCriteria:payload?.serchCriteria,
	// 				fromTestResult:payload&& payload.fromTestResult,
	// 				returnTest:"Tests"
	// 			}
	// 		});
	// 	}else{
	// 		if(historyObject.is_topic===true){
	// 			history.push({
	// 				pathname: "/test-result",
	// 				state: { 
	// 					scoreCardId:historyObject.scorecard_id,
	// 					resultTypeFull:false,
	// 					testCategory:null,
	// 					isTopic:true,
	// 					isReview:"t",
	// 					userId:payload?.userId,
	// 					name:payload?.name,
	// 					push2:payload?.push2,
	// 					push:payload?.push,
	// 					sd:payload?.sd,
	// 					ed:payload?.ed,
	// 					sded:payload?.sded,
	// 					title2:payload?.title2,
	// 					title:payload?.title,
	// 					serchCriteria:payload?.serchCriteria,
	// 					routeBack:payload?.routeBack,
	// 					fromTestResult:payload&& payload.fromTestResult,
	// 					returnTest:"Tests"
	// 				}
	// 			});
	// 		}else{
	// 			if(historyObject.category_type==="MIXED"){
	// 				history.push({
	// 					pathname: "/test-result",
	// 					state: { 
	// 						scoreCardId:historyObject.scorecard_id,
	// 						resultTypeFull:false,
	// 						testCategory:historyObject.category_type,
	// 						isTopic:false,
	// 						isReview:"t",
	// 						userId:payload?.userId,
	// 						name:payload?.name,
	// 						push2:payload?.push2,
	// 						push:payload?.push,
	// 						sd:payload?.sd,
	// 						ed:payload?.ed,
	// 						sded:payload?.sded,
	// 						title2:payload?.title2,
	// 						title:payload?.title,
	// 						serchCriteria:payload?.serchCriteria,
	// 						routeBack:payload?.routeBack,
	// 						fromTestResult:payload&& payload.fromTestResult,
	// 						returnTest:"Tests"
	// 					}
	// 				});
	// 			}else{
	// 				history.push({
	// 					pathname: "/test-result",
	// 					state: { 
	// 						scoreCardId:historyObject.scorecard_id,
	// 						resultTypeFull:true,
	// 						testCategory:historyObject.category_type,
	// 						isTopic:false,
	// 						isReview:"t",
	// 						push2:payload?.push2,
	// 						push:payload?.push,
	// 						sd:payload?.sd,
	// 						ed:payload?.ed,
	// 						sded:payload?.sded,
	// 						title2:payload?.title2,
	// 						title:payload?.title,
	// 						userId:payload?.userId,
	// 						name:payload?.name,
	// 						serchCriteria:payload?.serchCriteria,
	// 						routeBack:payload?.routeBack,
	// 						fromTestResult:payload&& payload.fromTestResult,
	// 						returnTest:"Tests"
	// 					}
	// 				});
	// 			}			
	// 		}	
	// 	}
	// };
	const tooltipTittle = (record) => {
		if(record === true) {
			return "Review Incorrect";
		}else{
			return "Review Test";
		}
	};
	const topTenColumns = [
		{
			title: '#',
			key: 'index',
			render: (value, item, index) => {
				return (totalCountReport -(pageSize * (page -1)) - index );
			},
			width:50
		},
		{
			title: 'Type',
			key: 'type',
			render: (record) => {
				if(record?.is_topic === true){
					return <span>Topic</span>;
				}else if(record?.is_category === true){
					return <span>Category</span>;
				}else if (record?.category_type === "CUSTOM"){
					return <span>Custom</span>;
				}
			},
			width:150,
			hidden: payload?.type ===1?false:true,
		},
		// flashcard columns
		{
			title: 'Type',
			key: 'type',
			filters: [
				{
					text: 'Textbook',
					value: 'Textbook',
				},
				{
					text: 'Topic',
					value: 'Topic',
				},
			],
			onFilter: (value, record) => {
				let flashcard_type = "";
				if (record.heading==="TB") {
					flashcard_type = "Textbook";
				}
				else {
					flashcard_type = "Topic";
				}
				return value.indexOf(flashcard_type)===0;
			},
			filteredValue:flashcardFilterTypevalue,
			render: (record) => {
				if (record?.heading === "TB") {
					return <span>Textbook</span>;
				} else {
					return <span>Topic</span>;
				}
			},
			width:150,
			hidden: payload?.is_flashcard ===1?false:true,
		},
		{
			title: 'Flashcard',
			key: 'title',
			// align: 'center',
			render: (record) => {
				return <Space>
					<Tooltip title={record?.title}>
						<span>{record?.abbreviation}</span>
					</Tooltip>
				</Space>;
			},
			hidden: payload?.is_flashcard ===1?false:true,
		},
		{
			title: 'Progress',
			key: 'progress',
			width:100,
			filters: [
				{
					text: 'Complete',
					value: 'Completed',
				},
				{
					text: 'Paused',
					value: 'Paused',
				},
			],
			onFilter: (value, record) => {
				let flashcard_progress = "";
				if (record.is_completed==="f") {
					flashcard_progress = "Paused";
				}
				else {
					flashcard_progress = "Completed";
				}
				return value.indexOf(flashcard_progress)===0;
			},
			filteredValue:flashcardFiltervalue,
			render: (record) => {
				let color="";
				let progress = '';
				if (record?.is_completed==="f") {
					progress = "Paused";
					color="red";
				}
				else {
					color="green";
					progress = "Completed";
				}
				return <Tag color={color}>
					{progress}
				</Tag>;
			},
			hidden: payload?.is_flashcard ===1?false:true,
		},
		{
			title:"Test",
			key: 'test',
			filters: [
				{
					text: 'FPP',
					value: 'FPP',
				},
				{
					text: 'SAED',
					value: 'SAED',
				},
				{
					text: 'PIET',
					value: 'PIET',
				},
				{
					text: 'Mixed',
					value: 'MIXED',
				},
				{
					text: 'Topics',
					value: 'Topics',
				},
				{
					text: 'Full Length',
					value: 'Full Length',
				},
			],
			hidden: payload?.type ===1 || payload.is_flashcard ===1?true:false,
			filteredValue:testFilterValue,
			onFilter: (value, record) => {
				if (value==='FPP'){
					return record.test.includes('FPP');
				}
				else if  (value=== 'SAED'){
					return record.test.includes('SAED');
				}
				else if  (value==='PIET'){
					return record.test.includes('PIET');
				}
				else if  (value==='MIXED'){
					return record.test.includes('MIXED');
				}
				else if  (value==='Full Length'){
					return record.test.includes('Full Length');
				}
				else if  (value==='Topics'){
					return  !record.test.includes('FPP') && !record.test.includes('PIET') && !record.test.includes('MIXED') && !record.test.includes('SAED') && !record.test.includes('Full Length');
				}
				else {
					return record.test;
				}
			},
			render: (record) => {
				return (
					<span>{record&&record.test?record.test:""}</span>
				);
			}
		},
		{
			title: "Tutorial",
			key: 'test',
			filters: [
				{
					text: 'FPP',
					value: 'FPP',
				},
				{
					text: 'SAED',
					value: 'SAED',
				},
				{
					text: 'PIET',
					value: 'PIET',
				},
				{
					text: 'Custom',
					value: 'CUSTOM',
				},
				{
					text: 'Topics',
					value: 'Topics',
				},
				{
					text: 'Mark for Later',
					value: 'MARK FOR LATER',
				},
				{
					text: 'Incorrect Answers',
					value: 'INCORRECT ANS',
				},
			],
			hidden: payload?.type ===1?false:true,
			filteredValue:testFilterValue,
			onFilter: (value, record) => {
				if (value==='FPP'){
					return record.test.includes('FPP');
				}
				else if  (value=== 'SAED'){
					return record.test.includes('SAED');
				}
				else if  (value==='PIET'){
					return record.test.includes('PIET');
				}
				else if  (value==='CUSTOM'){
					return record.test.includes('CUSTOM');
				}
				else if  (value==='Topics'){
					return  !record.test.includes('FPP') && !record.test.includes('PIET') && !record.test.includes('CUSTOM') && !record.test.includes('SAED');
				}
				else if  (value==='MARK FOR LATER'){
					return record.test.includes('MARK FOR LATER');
				}
				else if  (value==='INCORRECT ANS'){
					return record.test.includes('INCORRECT ANS');
				}
				else {
					return record.test;
				}
			},
			render: (record) => {
				if (record?.topic_abb !== null) {
					return <Space>
						<Tooltip title={record?.category_type}>
							<span>{record?.topic_abb}</span>
						</Tooltip>
					</Space>;
				} else {
					return <span>{record?.test}{" "}{record.category_type==="MARK FOR LATER"||record.category_type==="INCORRECT ANS"?"":`#${record?.attempt}`}</span>;
				}
			},
		},
		{
			title: 'Attempt',
			key: 'attempt',
			align: 'center',
			render: (record) => {
				return (
					<span>{record&&record.attempt?record.attempt:""}</span>
				);
			},
			hidden: payload?.type ===1 || payload?.is_flashcard ===1?true:false,
		},
		{
			title: 'Mode',
			key: 'mode',
			align: 'center',
			render: (record) => {
				return (
					<span>
						{record&&record.time != null? <HourglassOutlined/>:""}
					</span>
				);
			},
			hidden: payload?.type ===1 || payload?.is_flashcard ===1 ?true:false,
		},
		{
			title: 'Progress',
			key: 'progress',
			width:100,
			filters: [
				{
					text: 'Complete',
					value: 'Complete',
				},
				{
					text: 'Paused',
					value: 'Paused',
				},
				{
					text: 'In Progress',
					value: 'Inprogress',
				}
			],
			filteredValue:isFilterValue,
			onFilter: (value, record) => {
				let test_progress = "";
				if (record.status==="1") {
					test_progress = "Complete";
				} 
				else if (record.status==="2"){
					test_progress = "Paused";
				}
				else {
					test_progress = "Inprogress";
				}
				return value.indexOf(test_progress)===0;
			},
			render: record => {
				let color="";
				let ifcompleted = "";
				let progress = '';
				if (record.status==="1") {
					progress = "Completed";
					color="green";
					if(record.time != null&&record.is_timed&&record.is_timed==="t"){
						ifcompleted="in " + record.time;
						progress = "Completed" + "...";
					}
				}
				else {
					color="blue";
					if(record.time != null&&record.is_timed&&record.is_timed==="t"){
						ifcompleted=<span>{record.remaining_test_time}<span className="cyan"> remaining</span></span>;
						progress = <span><span>{record.answered_questions }</span><span onClick={()=>{window.open(constants.BaseUrl + "/admin-result-json?"+record.scorecard_id);}}>/</span><span>{record.total_questions} {"..."}</span> </span>;
					}
					else {
						progress =<span><span>{record.answered_questions}</span><span onClick={()=>{window.open(constants.BaseUrl + "/admin-result-json?"+record.scorecard_id);}}>/</span><span>{record.total_questions}</span></span>;
					}
				}
				return(
					<>
						<Tooltip title={ifcompleted}>
							{record?.status==="2"?
								<Tag color={"red"}>
									{"Paused"}
								</Tag>:record?.status==="0"?<Tag color={"orange"}>
									{"In Progress"}
								</Tag>:null}
							<Tag color={color}>
								{progress}
							</Tag>
						</Tooltip>
					</>
				);
			},
			hidden: payload?.type ===1 || payload?.is_flashcard ===1?true:false,
		},
		{
			title: 'Progress',
			key: 'progress',
			width:200,
			filters: [
				{
					text: 'Complete',
					value: 'Complete',
				},
				{
					text: 'In Progress',
					value: 'Inprogress',
				},
			],
			onFilter: (value, record) => {
				let test_progress = "";
				if (record.status==="1") {
					test_progress = "Complete";
				}
				else {
					test_progress = "Inprogress";
				}
				return value.indexOf(test_progress)===0;
			},
			filteredValue:isFilterValue,
			render: record => {
				let color="";
				// let ifcompleted = "";
				let progress = '';
				if (record.status==="1") {
					progress = "Completed";
					color="green";
				}
				else {
					color="blue";
					// if(record.time != null){
					// ifcompleted=<span>{record.remaining_test_time}<span className="cyan"> remaining</span></span>;
					progress = <span><span>{record.answered_questions }</span><span onClick={()=>{window.open(constants.BaseUrl + "/admin-result-json?"+record.scorecard_id);}}>/</span><span>{record.total_questions}</span> </span>;
					// }
					// else {
					// 	progress =<span><span>{record.answered_questions}</span><span onClick={()=>{window.open(constants.BaseUrl + "/admin-result-json?"+record.scorecard_id);}}>/</span><span>{record.total_questions}</span></span>;
					// }
				}
				return(
					<>
						{record?.status==="0"?<Tag color={"orange"}>
							{"In Progress"}
						</Tag>:null}
						<Tag color={color}>
							{progress}
						</Tag>
					</>
				);
			},
			hidden: payload?.type ===1?false:true,
		},
		{
			title: 'Actions',
			align: 'center',
			render: (record,object) => {
				return (
					<Space>
						{record&&record.status&&record.status==="1"?
							<Tooltip title={ tooltipTittle(record.is_full_length) }><Button type="link" icon={<FileSearchOutlined/>} onClick={()=>redirectResult(record,object)}></Button></Tooltip>
							:""
						}
					</Space>
				);
			},
			hidden: payload?.type ===1 || payload?.is_flashcard ===1?true:false,
		},
		{
			title: 'Date',
			key: 'attemptedOn',
			sorter: (a, b) => new Date(a.attempt_date) - new Date(b.attempt_date),
			// defaultSortOrder: 'descend',
			render: (record) => {
				return (
					<span>{record&&record.attempt_date ? (<Tooltip title={moment(record.attempt_date).format("MM/DD/YYYY")+" "+record.time}><span>{moment(record.attempt_date).format("MM/DD/YYYY")}</span></Tooltip>) : ("-")}</span>
				);
			},
			align:payload?.type ===1 || payload?.is_flashcard === 1? 'left':null
		},
		{
			title: 'Qns',
			key: 'questions',
			align:'center',
			// className: 'td-right',
			render: (record) => {
				let per_result = record.total_corr_ans + "/" + record.total_questions;
				if (record.status==="1"){
					if(record.is_autoclose==="f"){
						return (
							<span>{per_result}</span>
						);
					}else{
						return (
							<><Space size={1}><span className='mx-0 w-auto'>{record.total_corr_ans}</span><span className="mx-0 w-auto errorMsg">/</span><span className='mx-0 w-auto'>{record.total_questions}</span></Space></>
						);
					}
				}
				else {
					return (
						<div></div>
					);
				}
			},
			hidden: payload?.is_flashcard ===1?true:false,
		},
		{
			title: 'Pts',
			key: 'points',
			dataIndex: "points",
			align: 'center',
			className: 'td-right',
			render: (record) => {
				if(record === "0"){
					return <span></span>;
				}else{
					return <span className="p-relative" style={{ width:"30px" }}>{record ? record:""}</span>;
				}
			},
		},
		{
			title: 'Result',
			key: "result",
			align:'center',
			className: 'td-right',
			sorter: (a, b) => a.percentage - b.percentage,
			hidden: payload?.type ===1 || payload?.is_flashcard ===1?true:false,
			filters: [
				{
					text: 'Pass',
					value: 'pass',
				},
				{
					text: 'Did not pass',
					value: 'fail',
				},
			],
			filteredValue:passFilterValue,
			onFilter: (value, record) => {
				let result = "";
				if ( record.status==="1"){
					let attemptedDate=record.attempt_date?moment(record.attempt_date).format("MM-DD-YYYY"):"";
					if(new Date(attemptedDate)>=new Date(passPercentageDate)){
						if (record.percentage >= constants.percentagePass){
							result = "pass";
						}else if (record.percentage < constants.percentagePass){
							result = "fail";
						}
					}else if(new Date(attemptedDate)<new Date(passPercentageDate)){
						if (record.percentage >= constants.percentPassForOlder) {
							result = "pass";
						}
						else if (record.percentage < constants.percentPassForOlder) {
							result = "fail";
						}
					}
					return result.indexOf(value)===0;
				}
			},
			render: record => {
				if (record.status==="1") {
					let attemptedDate=record.attempt_date?moment(record.attempt_date).format("MM-DD-YYYY"):"";
					if(new Date(attemptedDate)>=new Date(passPercentageDate)){
						if (record.percentage >= constants.percentagePass){
							return (
								<div>
									<span className="p-relative">{record.percentage}% <StarFilled className="yellow mytest-star"/></span>
								</div>
							);
						}else{
							return (
								<div>
									<span className="">{record.percentage}%</span>
								</div>
							);
						}
					}else if(new Date(attemptedDate)<new Date(passPercentageDate)){
						if (record.percentage >= constants.percentPassForOlder){
							return (
								<div>
									<span className="p-relative">{record.percentage}% <StarFilled className="yellow mytest-star"/></span>
								</div>
							);
						}else{
							return (
								<div>
									<span className="">{record.percentage}%</span>
								</div>
							);
						}
					}
				}
				else{
					return (
						<div></div>
					);
				}
			}
		}
	].filter(item => !item.hidden);
	function onChange(pagination, filters,_sorter,other) {
		if(other){
			setTotalCountReport(other?.currentDataSource?.length);
		}
		if(pagination){
			setPage(pagination.current);
			setPageSize(pagination.pageSize);
		}
		setTestFilterValue(filters.test);
		setIsFilterValue(filters.progress);
		setFlashcardFiltervalue(filters.progress);
		setFlashcardFilterTypevalue(filters.type);
		setpassFilterValue(filters.result);
	}
	return (
		<div className="div">
			<div className="p-3">
				<Breadcrumb>
					<Breadcrumb.Item ><a onClick = {() => {
						history.push({ pathname :"/admin-dashboard" });
					}}>Home</a></Breadcrumb.Item>
					<Breadcrumb.Item ><a onClick = {() => {
						history.push({ pathname :"/analysis-menu" });
					}}>Analysis</a></Breadcrumb.Item>
					{payload&& payload.fromTestResult &&(
						<Breadcrumb.Item><a onClick={() => {
							history.push({ pathname:"/test-results", });
						}}>{constants.testResults}</a></Breadcrumb.Item>
					)}
					{payload&& payload.fromFlashcardResult &&(
						<Breadcrumb.Item><a onClick={() => {
							history.push({ pathname:"/flashcard-result", });
						}}>{constants.flashcardResults}</a></Breadcrumb.Item>
					)}
					{payload&& payload.editStudent &&(
						<Breadcrumb.Item><a onClick={() => {
							sessionStorage.setItem("showEditSaveButton","show");
							history.push({ pathname:"/edit-student", });
						}}>Edit Student User</a></Breadcrumb.Item>
					)}
					{payload&& payload.fromTutorialResult &&(
						<Breadcrumb.Item><a onClick={() => {
							history.push({ pathname:"/tutorial-result", });
						}}>{constants.tutorialResults}</a></Breadcrumb.Item>
					)}
					{payload&& payload.title &&(
						<Breadcrumb.Item><a onClick={() => {
							history.push({ 
								pathname:payload&& payload.push2 ? payload.push2 :payload.push ,
								state : {
									sd : payload && payload.sded ===2 ? "":payload.sd, 
									ed : payload && payload.sded ===2 ? "": payload.ed,
								} 
							});
						}}>{payload&&payload.title2 ? payload.title2 :payload.title}</a></Breadcrumb.Item>
					)}
					{payload&&payload.title2 && (
						<Breadcrumb.Item><a onClick={() => {
							history.push({ 
								pathname:payload&& payload.push ,
								state : {
									sd : payload && payload.sded ===2 ? "":payload.sd, 
									ed : payload && payload.sded ===2 ? "": payload.ed,
								} 
							});
						}}>{payload&&payload.title}</a></Breadcrumb.Item>
					)}
					{payload&&payload.sd && payload&&payload.ed && (
						<Breadcrumb.Item ><a onClick = {() => {
							history.push({ pathname :"/registered-students" });
						}}>Registered Students</a></Breadcrumb.Item>
					)}
					<Breadcrumb.Item><span>{payload?.is_flashcard ===1 ? "Flashcards" : payload?.type ===1 ? "Tutorials" : "Tests" }</span></Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<BackTop/>
			<Row gutter={[24, 8]} className='my-test'>
				<Col xs={24} sm={24} md={24} lg={24}>
					<Card size="small" title={<span className="text-uppercase">{payload?.type ===1 ? "Tutorials" : payload?.is_flashcard ===1 ?"Flashcards" : "Tests"} Taken {`- ${payload &&  payload.is_active==="4"?"-":payload &&payload.name}`}</span>} extra={
						<Form layout="horizontal">
							<Form.Item className="label-default mb-0" label={payload?.type ===1 ? "Tutorials taken" : payload?.is_flashcard ===1 ?"Flashcards taken" : "Tests taken"}>
								<Select style={{ width: 175 }} onChange={onChangeTable} placeholder="Select period" >
									<Option value="1" >This month</Option>
									<Option value="2">Last month</Option>
									<Option value="3">Last 3 months</Option>
									<Option value="4">Last 6 months</Option>
									<Option value="5">Last 12 months</Option>
									<Option value="6">All</Option>
								</Select>
							</Form.Item>
						</Form>
					}>
						<span className="px-3">{`${totalCountReport} record(s) found.`}</span>
						<Table className="gap-table" 
							locale={{ emptyText: 'No Records Found' }}
							onChange={onChange}
							dataSource={data} 
							columns={topTenColumns}
							pagination={pagination} 
						/>
					</Card>
				</Col>
			</Row> 
		</div>
	);
};
export default Test;