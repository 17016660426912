/*
File Name: Book.js
Author: Dahlin Carneiro
Modified On: 08/06/2022
Description:Book cover section module.
*/
import { Col, Image } from "antd";
const Book = () => {
	return (
		<Col
			lg={{
				span: 24,
				offset: 0
			}}
			xl={{
				span: 8,
				offset: 1
			}}
			className="book-col flex-middle">
			<Image src="imgs/slp-6th-edition.png" alt="SLP 6th edition" preview={false}/>
		</Col>
	);
};
export default Book;