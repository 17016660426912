/*
File Name: 
Author: 
Description: 
API's used:
1) 
*/
import React, { useEffect, useState } from "react";
import {
	Breadcrumb,
	Form,
	Row,
	Col,
	Card,
	Button,
	Space,
	BackTop,
	Radio,
	// Checkbox,
	InputNumber,
	message,
	Spin,
	// message
} from "antd";
import "../../../styles/admin.css";
import { useHistory } from "react-router";
// import { fetchApi } from "../../../services/api";
import constants from "../../../constants/constants";
import { fetchAdminDownloadApi } from "../../../services/api";
import moment from "moment/moment";
import Loader from "../../Common/Cred/Loader";
const CodeGeneration = () => {
	const history = useHistory();
	const [form] = Form.useForm();
	const [isfree,setIsFree]=useState("0");
	const [loader,setLoader]=useState(false);
	// const [registerenewValue,setregisterRenewValue]=useState("0");
	//Function to reset form
	const resetPromoform=()=>{
		form.resetFields();
		setIsFree(false);
		// setregisterRenewValue("0");
	};
	//On CheckBox Change
	const OnCheckBoxChange=(e)=>{
		setIsFree(e.target.checked?"1":"0");
	};
	//On radio Change
	// const Onradiochange=(e)=>{
	// 	setregisterRenewValue(e.target.value);
	// };
	const onFormFinish=(values)=>{
		let payload=null;
		payload={
			txt_duration:values.duration,
			txt_noOfCodes:values.noOfCodes,
			slc_type:"0",
			is_free:isfree
		};
		setLoader(true);
		fetchAdminDownloadApi(`/generate-codes`, "post", payload).then(
			(res) => {
				if(res){
					let url,a;
					url = window.URL.createObjectURL(
						new Blob([res], { type: "application/vnd.ms-excel" }),
						{ type: "data:attachment/xls" }
					);
					let todayDate= moment().format(constants.dateFormat);
					let filename="";
					if(constants.EnviromentName!=="qa"&&constants.EnviromentName!=="staging"&&constants.EnviromentName!=="dev"){
						filename="PromoCodes("+todayDate+").xls";
					}else{
						filename=constants.EnviromentName+"-PromoCodes("+todayDate+").xls";
					}
					a = document.createElement("a");
					a.download = filename;
					a.href = url;
					a.dispatchEvent(new MouseEvent("click"));
					setLoader(false);
				}else{
					message.error("Failed to download");
					setLoader(false);
				}
			}
		);
	};
	useEffect(() => {}, []);
	// 
	return (
		<>
			<Spin spinning={loader} size="large" indicator={<Loader/>}>
				<div className="gray-bg h-86vh">
					<div className="p-3">
						<Breadcrumb>
							<Breadcrumb.Item><a onClick={() => {
								history.push({ pathname: "/admin-dashboard" });
							}}>Home</a></Breadcrumb.Item>
							<Breadcrumb.Item ><a onClick = {() => {
								history.push({ pathname :"/masters-menu" });
							}}>Masters</a></Breadcrumb.Item>
							<Breadcrumb.Item>Generate Promocode</Breadcrumb.Item>
						</Breadcrumb>
					</div>
					<div className="p-4 ">
						<BackTop/>
						<Row gutter={[24, 24]} className='mt-4'>
							<Col xs={24} 
								sm={{ 
									span: 22,
									offset: 1
								}} 
								lg={{ 
									span: 14,
									offset: 6
								}}>
								<Card size="small" title={<span className="text-uppercase">{constants.CodeGeneration}</span>}>
									<Form layout="vertical" form={form} onFinish={onFormFinish}>
										<Row gutter={[24, 0]} className=''>
											<Col xs={24} sm={12} md={12} lg={12}>
												<Form.Item className="label-default"
													label="Duration (in months)"
													name="duration"
													rules={[
														{
															required: true,
															message: 'Enter months',
														}
													]}
												>
													<InputNumber min={1} className="w-100" placeholder="Months"/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={12} md={12} lg={12}>
												<Form.Item className="label-default"
													label="No. of codes"
													name="noOfCodes"
													rules={[
														{
															required: true,
															message: 'Enter no. of codes',
														}
													]}
												>
													<InputNumber min={1} className="w-100" placeholder="No. of codes"/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={12} md={12} lg={12}>
												<Form.Item name="registerRenew">
													<span>Payment Mode : </span>
													<Radio.Group defaultValue="1" buttonStyle="solid" onChange={(e)=>OnCheckBoxChange(e)}>
														<Radio.Button value="0">Free</Radio.Button>
														<Radio.Button value="1">Paid</Radio.Button>
													</Radio.Group>
												</Form.Item>
											</Col>
											<Col xs={24} sm={12} md={12} lg={12}>
												{/* <Form.Item name="paymentMode"><span>Payment Mode : <Checkbox onChange={(e)=>OnCheckBoxChange(e)}>Free</Checkbox></span></Form.Item> */}
											</Col>
											<Col xs={24} sm={24} md={24} lg={24}>
												<Form.Item >
													<Space align="end" direction="horizontal" className="button-wrapper">
														<Button onClick={resetPromoform}>
													Reset
														</Button>
														<Button className="btnDb" htmlType="submit" >
													Generate
														</Button>
														{/* <Button className="btnLb"  onClick={() => { history.push("/promo-code-list"); }}>
													Promo Codes
												</Button>											 */}
													</Space>
												</Form.Item>
											</Col>
										</Row>
									</Form>
								</Card>
							</Col>
						</Row> 
					</div>
				</div>
			</Spin>
		</>
	);
};
export default CodeGeneration;
